@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body {
  scroll-behavior: smooth;
  color: #2b2b2b;
  font-family: "Poppins", sans-serif !important;
}

.loader-con{
  min-height: 50vh;
}

.navbar-brand{
    img {
        width: 200px;
        max-height: 100px;
        @media (max-width: 575.98px) {
            max-width: 160px;
            width: 100%;
        }
    }
}

.tracking-logo{
  position: absolute;
  left:0rem;
  top:0rem;
}

.shipment-btn {
  transition: 0.2s linear;
  background: linear-gradient(-45deg, #5e12bd, #573fdd);
  color: #fff !important;
  border-radius:50px !important;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
    background: linear-gradient(-45deg, #5e12bd, #573fdd);
    color: #fff !important;
  }
}

.tracking-terms {
  color: #2b2b2b;
  .term-icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    padding: 1rem;
    border-radius: 50%;
    border: 1px solid #222;
  }
  
  .term-text {
    width: calc(100% - 60px);
    padding-left: 1rem;
    padding-top: 1rem;
  }
  
  .term-icon img {
    width: 100%;
  }
}

.merchant-con .logo-con {
  width: 100px;
  height: 100px;
  @media (max-width: 575.98px) {
    width: 80px;
    height: 80px;
  }
}

.merchage-logos {
  max-width: 800px;
  margin: 0 auto;
  position: relative;

  .logo-con {
    margin: 0 auto;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0px 0px 10px 5px rgba(72, 72, 72, 0.05),
      0px 0px 50px 50px rgba(72, 72, 72, 0.05);
    animation: movelogo 3s ease-in-out infinite;

    img {
       border-radius: 50%;
    }
  }
  
}

footer{
  background: #f7f7f7;
}

/* ------page not found ------------ */
.not-found-page{
  background: #e6e6e6;
  min-height: 100vh;

  .img-con{
    width: 500px;
    margin-top:5rem;
    user-select: none;
  }
}


/* ------------end new design-------------------- */
@keyframes movelogo {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.merchage-logos [class*="col-"] {
  margin-bottom: 2rem;
}


@media (min-width: 1px) and (max-width: 767.98px) {
  h3 {
    font-size: 1.5rem !important;
  }
}